import React from 'react';

import i18next from '~/i18n/config';

import { ReactComponent as AudienceIcon } from './assets/audience.svg';
import { ReactComponent as CheckIcon } from './assets/check.svg';
import { ReactComponent as ContinueIcon } from './assets/continue.svg';
import { ReactComponent as DetectIcon } from './assets/detect.svg';
import { ReactComponent as HumanizeIcon } from './assets/humanize.svg';
import { ReactComponent as ImproveIcon } from './assets/improve.svg';
import { ReactComponent as LongerIcon } from './assets/longer.svg';
import { ReactComponent as ShorterIcon } from './assets/shorter.svg';
import { ReactComponent as SummarizeIcon } from './assets/summarize.svg';
import { ReactComponent as ToneIcon } from './assets/tone.svg';
import { ReactComponent as TranslateIcon } from './assets/translate.svg';

const { t } = i18next;

export type AiEditOptionType = {
  text: string;
  value: string;
  icon: JSX.Element;
};

export const AI_EDIT_OPTIONS: AiEditOptionType[] = [
  {
    text: t('ai_edit_options:texts[0]', 'Improve'),
    value: t('ai_edit_options:values[0]', 'improve this text'),
    icon: <ImproveIcon />,
  },
  {
    text: t('ai_edit_options:texts[1]', 'Continue Writing'),
    value: t('ai_edit_options:values[1]', 'continue writing this text'),
    icon: <ContinueIcon />,
  },
  {
    text: t('ai_edit_options:texts[2]', 'Shorter'),
    value: t('ai_edit_options:values[2]', 'make this text shorter'),
    icon: <ShorterIcon />,
  },
  {
    text: t('ai_edit_options:texts[3]', 'Humanize'),
    value: t('ai_edit_options:values[3]', 'humanize'),
    icon: <HumanizeIcon />,
  },
  {
    text: t('ai_edit_options:texts[4]', 'Longer'),
    value: t('ai_edit_options:values[4]', 'make this text longer'),
    icon: <LongerIcon />,
  },
  {
    text: t('ai_edit_options:texts[5]', 'Detect Ai'),
    value: t('ai_edit_options:values[5]', 'detect ai'),
    icon: <DetectIcon />,
  },
  {
    text: t('ai_edit_options:texts[6]', 'Summarize'),
    value: t('ai_edit_options:values[6]', 'summarize this text'),
    icon: <SummarizeIcon />,
  },
  {
    text: t('ai_edit_options:texts[7]', 'Check Plagiarism'),
    value: t('ai_edit_options:values[7]', 'check plagiarism'),
    icon: <CheckIcon />,
  },
  {
    text: t('ai_edit_options:texts[8]', 'Change Tone'),
    value: t(
      'ai_edit_options:values[8]',
      'change tone of voice of this text to [paste new tone]',
    ),
    icon: <ToneIcon />,
  },
  {
    text: t('ai_edit_options:texts[9]', 'Explain'),
    value: t('ai_edit_options:values[9]', 'explain this text'),
    icon: <ImproveIcon />,
  },
  {
    text: t('ai_edit_options:texts[10]', 'Change Audience'),
    value: t(
      'ai_edit_options:values[10]',
      'rewrite text for the next audience [paste new audience]',
    ),
    icon: <AudienceIcon />,
  },
  {
    text: t('ai_edit_options:texts[11]', 'Translate'),
    value: t(
      'ai_edit_options:values[11]',
      'translate this text to: [output language]',
    ),
    icon: <TranslateIcon />,
  },
];
